.new-post-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    margin-top: 40px;
  }
  
  .new-post-title {
    margin-bottom: 16px;
    color: #434652;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .new-post-input {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  
  .new-post-button {
    align-self: flex-end;
    margin-top: 16px;
    background-color: #434652;
  }
  
  .new-post-category {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  