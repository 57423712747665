.header {
  background-color: #f5f5f5;
  border-radius: 40px;
  padding: 10px 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.app-name {
  font-size: 35px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  margin-right: 20px;
}

.search-field {
  width: 300px;
}
  
.profile svg {
  font-size: 50px;
}

.newPost {
margin-right: 0px;
}

.newPost svg {
font-size: 45px;
}