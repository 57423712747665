.postContainer {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
}

.header {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.department {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  margin-bottom: 0px;
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 2px 10px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 28px;
  margin: 10px 0;
  flex-grow: 1;
}

.postedBy {
  font-size: 14px;
  color: #888;
  margin-left: 10px;
  white-space: nowrap;
}

.tagContainer {
  padding-bottom: 10px;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 2px 6px;
  margin-right: 5px;
  font-size: 14px;
  color: #0079d3;
}

.content {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
}

.likes,
.comments {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.likes svg,
.comments svg {
  margin-right: 3px; /* Adds space between the icon and the count */
}
